import config from '../constants/config';

const createEvent = (event, params) => {
  if (!window.gtag || config.env !== 'production') {
    return;
  }

  window.gtag('event', event, params);
};

export default {
  createEvent,
};
