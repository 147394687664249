import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { priceFormat, removeVietnameseTones } from '../../utils/string';
import TransactionTable from './TransactionTable';
import { getTransations } from '../../services/transaction';
import gtag from '../../utils/gtag';

export default function Home() {
  const [transactions, setTransactions] = useState(null);
  const [search, setSearch] = useState('');
  const isMobile = window.innerWidth < 600;
  const searchValue = removeVietnameseTones(search).toLowerCase();

  const [loading, setLoading] = useState(false);
  const [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [pinnedSearchValue, setPinnedSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState('');
  const [source, setSource] = useState('all');
  const [totalResults, setTotalResults] = useState(0);
  const [date, setDate] = useState('');
  // const [amountFrom, setAmountFrom] = useState('');
  // const [amountTo, setAmountTo] = useState('');

  const dates = [
    '23/09/2024',
    '22/09/2024',
    '21/09/2024',
    '20/09/2024',
    '19/09/2024',
    '18/09/2024',
    '17/09/2024',
    '16/09/2024',
    '15/09/2024',
    '14/09/2024',
    '13/09/2024',
    '12/09/2024',
    '11/09/2024',
    '10/09/2024',
    '09/09/2024',
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    filterList({
      searchValue,
      sort,
      source,
      page: newPage,
    }).then((transactions) => {
      setTransactionsFiltered(transactions.items || []);
      setPinnedSearchValue(searchValue);
      setTotalResults(transactions.total || 0);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);

    filterList({
      searchValue,
      sort,
      source,
      page,
      limit: Number(event.target.value),
    }).then((transactions) => {
      setTransactionsFiltered(transactions.items || []);
      setPinnedSearchValue(searchValue);
      setTotalResults(transactions.total || 0);
    });
  };

  const handleSort = async (filter) => {
    if (filter.sort) {
      setSort(filter.sort);
    }
    if (filter.source) {
      setSource(filter.source);
    }
    if (filter.date) {
      setDate(filter.date);
    }
    setPage(0);

    const filteredTransactions = await filterList({
      page: 0,
      data: transactions,
      searchValue,
      sort: filter.sort || sort,
      source: filter.source || source,
      date: filter.date || date,
    });

    setTransactionsFiltered(filteredTransactions.items || []);
    setPinnedSearchValue(searchValue);
    setTotalResults(filteredTransactions.total || 0);
  };

  const filterList = async ({
    limit,
    page,
    searchValue,
    sort,
    source,
    date,
  } = {}) => {
    const sortObject = {};
    const filterObject = {};
    if (sort) {
      sortObject.amount = sort;
    }
    if (source && source !== 'all') {
      filterObject.source = source;
    }
    if (date) {
      filterObject.date = date;
    }

    try {
      setLoading(true);
      const transactionResponse = await getTransations({
        page,
        limit: limit || rowsPerPage,
        sort: sortObject,
        searchValue,
        filter: filterObject,
      });

      return transactionResponse;
    } finally {
      setLoading(false);
    }

    // let filteredData = [...(data || [])];

    // if (search) {
    //   // Filter transactions by notes
    //   filteredData = filteredData.filter(
    //     (transaction) =>
    //       (transaction.notes || '').toLowerCase().includes(search) ||
    //       String(transaction.amount || '').includes(search) ||
    //       (transaction.name || '').toLowerCase().includes(search)
    //   );
    // }

    // if (sort) {
    //   if (sort === -1 || sort === 1) {
    //     filteredData = filteredData.sort((a, b) => {
    //       if (sort === 1) {
    //         return a.amount - b.amount;
    //       }
    //       return b.amount - a.amount;
    //     });
    //   } else if (sort === 2) {
    //     filteredData = filteredData.sort(
    //       // date: DD/MM/YYYY
    //       (a, b) => {
    //         const d1 = new Date(
    //           a.date.substring(6, 10),
    //           Number(a.date.substring(3, 5)) - 1,
    //           a.date.substring(0, 2)
    //         );
    //         const d2 = new Date(
    //           b.date.substring(6, 10),
    //           Number(b.date.substring(3, 5)) - 1,
    //           b.date.substring(0, 2)
    //         );

    //         return d2.getTime() - d1.getTime();
    //       }
    //     );
    //   } else if (sort === 3) {
    //     filteredData = filteredData.sort(
    //       // date: DD/MM/YYYY
    //       (a, b) => {
    //         const d1 = new Date(
    //           a.date.substring(6, 10),
    //           Number(a.date.substring(3, 5)) - 1,
    //           a.date.substring(0, 2)
    //         );
    //         const d2 = new Date(
    //           b.date.substring(6, 10),
    //           Number(b.date.substring(3, 5)) - 1,
    //           b.date.substring(0, 2)
    //         );

    //         return d1.getTime() - d2.getTime();
    //       }
    //     );
    //   }
    // }

    // if (source && source !== 'all') {
    //   if (source === 'vcb') {
    //     filteredData = filteredData.filter(
    //       (transaction) => !transaction.source || transaction.source === 'vcb'
    //     );
    //   } else {
    //     filteredData = filteredData.filter(
    //       (transaction) => transaction.source === source
    //     );
    //   }
    // }

    // return _cloneDeep(filteredData);
  };

  const roundToNearestHalfSecond = (ms) => {
    return Math.round(ms / 500) * 500;
  }

  const handleSearch = async (value, tracking = false) => {
    setPage(0);

    const startAt = new Date().getTime();
    const filteredTransactions = await filterList({
      page: 0,
      data: transactions,
      searchValue: value,
      sort,
      source,
      date,
    });
    const finishAt = new Date().getTime();

    setTransactionsFiltered(filteredTransactions.items || []);
    setPinnedSearchValue(value);
    setTotalResults(filteredTransactions.total || 0);

    if (tracking) {
      gtag.createEvent('user_search_data', {
        event_duration: roundToNearestHalfSecond(finishAt - startAt),
        search_value: value,
        search_length: value.length,
      });
    }
  };

  useEffect(() => {
    if (searchValue) {
      // handleSearch(searchValue, true);
    } else {
      // getDefaultTransactions();
    }
  }, [searchValue]);

  const getDefaultTransactions = async () => {
    const data = await getTransations({ isDefault: true });
    setTransactionsFiltered(data?.items || []);
    setTotalResults(data?.total || 0);
    setTransactions(data?.items || []);
  };

  useEffect(() => {
    if (transactions?.length) {
      return;
    }

    if (transactions === null) {
      getDefaultTransactions();
    }
  }, [transactions]);

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '1400px' }} padding={2}>
      <Box>
        <Typography variant='h5' align='center'>
          <img src='/8f70378fd495.jpg' height={150} />
          <div>Sao kê tiền gửi đến Ủy ban Trung ương MTTQ Việt Nam</div>
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          paddingTop={2}
          gap={2}
          component='form'
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(searchValue, true);
          }}
        >
          <Box flex={1}>
            <TextField
              fullWidth
              id='outlined-basic'
              label='Tìm kiếm'
              variant='outlined'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder='Tìm kiếm theo tên, nội dung hoặc mã giao dịch'
            />
          </Box>
          <Box>
            <Button
              sx={{ height: '100%', lineHeight: 'normal' }}
              startIcon={<SearchIcon />}
              variant='contained'
              type='submit'
              disabled={loading}
            >
              {isMobile ? 'Tìm' : 'Tìm Kiếm'}
            </Button>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' flexWrap='wrap' gap={2} mt={2}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ width: 59 }}>Sắp Xếp</Box>
            <Box>
              <Select
                value={sort}
                sx={{ width: 100 }}
                onChange={(e) => handleSort({ sort: e.target.value })}
              >
                <MenuItem value={-1}>Tiền Giảm Dần</MenuItem>
                <MenuItem value={1}>Tiền Tăng Dần</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ width: 59 }}>Nguồn</Box>
            <Box>
              <Select
                value={source}
                sx={{ width: 100 }}
                onChange={(e) => handleSort({ source: e.target.value })}
              >
                <MenuItem value={'all'}>Tất Cả</MenuItem>
                <MenuItem value={'vcb'}>Vietcombank</MenuItem>
                <MenuItem value={'vt'}>Vietinbank</MenuItem>
                <MenuItem value={'bidv'}>BIDV</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ width: 59 }}>Ngày</Box>
            <Box>
              <Select
                value={date}
                sx={{ width: 100 }}
                onChange={(e) => handleSort({ date: e.target.value })}
              >
                {dates.map((date) => (
                  <MenuItem key={date} value={date}>
                    {date.split('/')[0]}/{date.split('/')[1]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Box>Kết Quả: {priceFormat(totalResults || 0)}</Box>
          </Box>

          {/* <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Box>Số Tiền Từ</Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TextField sx={{ width: 220 }} value={amountFrom} />
              <span>-</span>
              <TextField sx={{ width: 220 }} value={amountTo} />
            </Box>
          </Box> */}
        </Box>
      </Box>

      <TransactionTable
        transactions={transactionsFiltered}
        loading={!transactions?.length}
        count={totalResults}
        searchValue={pinnedSearchValue}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box mt={3}>
        <Typography>Liên hệ/Báo lỗi: nhvietanh.dev@gmail.com</Typography>
      </Box>
    </Box>
  );
}
