import { openDB } from 'idb';
import DEFAULT_DATA from '../assets/defaultData.json';

const CURRENT_DATA_VERSION = 7;

const clientDataVersion = () => {
  const clientVersion = localStorage.getItem('cdv');
  return clientVersion ? Number(clientVersion) : 0;
};

const updateClientDataVersion = () => {
  localStorage.setItem('cdv', CURRENT_DATA_VERSION);
};

const getAllTransactions = async () => {
  const db = await openDB('transaction-database', 1, {
    upgrade(db) {
      // Create a store for transactions if it doesn't exist
      if (!db.objectStoreNames.contains('transactions')) {
        db.createObjectStore('transactions');
      }
    },
  });

  const cachedDatas = await Promise.all([
    db.get('transactions', 'd-0'),
    db.get('transactions', 'd-1'),
    db.get('transactions', 'd-2'),
    db.get('transactions', 'd-3'),
    db.get('transactions', 'd-4'),
    db.get('transactions', 'd-5'),
    db.get('transactions', 'd-6'),
    db.get('transactions', 'd-7'),
  ]);

  // const cachedData = await db.get('transactions', 'all');
  const cachedData = cachedDatas.flat().filter((d) => d);
  if (cachedData?.length && clientDataVersion() === CURRENT_DATA_VERSION) {
    return cachedData;
  }

  const startAt = new Date().getTime();
  const data = await Promise.all([
    fetch('/trans/vcb14.json').then((response) => response.json()),
    fetch('/trans/vcb13.json').then((response) => response.json()),
    fetch('/trans/vcb12.json').then((response) => response.json()),
    fetch('/trans/vcb11.json').then((response) => response.json()),
    fetch('/trans/bidv.json').then((response) => response.json()),
    fetch('/trans/transactions.json').then((response) => response.json()),
    fetch('/trans/vt.json').then((response) => response.json()),
    fetch('/trans/vt1314.json').then((response) => response.json()),
  ]);

  const finishAt = new Date().getTime();
  if (window.gtag) {
    window.gtag('event', 'loaded_all_data', {
      request_time: finishAt - startAt,
    });
  }

  data.forEach((d, i) => {
    db.put('transactions', d, `d-${i}`);
  });

  let transactionData = data.flat();
  // Cache the data in IndexedDB
  // db.put('transactions', transactionData, 'all');
  updateClientDataVersion();

  return transactionData;
};

const getTransations = async ({
  page = 0,
  limit = 50,
  sort = {},
  searchValue,
  filter = {},
  isDefault = false,
} = {}) => {
  if (isDefault) {
    return DEFAULT_DATA.data;
  }

  if (Object.keys(sort).length === 0) {
    sort.date = -1;
  }

  const apiUrl =
    'https://5j3o3ltoue7iwickkffjgjcxja0xoyun.lambda-url.ap-southeast-1.on.aws/';
  const params = {
    type: 'search',
    query: {
      page,
      limit,
      sort,
      searchValue,
      filter,
    },
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const responseJson = await response.json();
    return responseJson.data;
  } catch (error) {
    console.error(error);
    return {
      total: 0,
      page: 0,
      items: [],
    };
  }
};

export { getAllTransactions, getTransations };
