import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          [createTheme().breakpoints.down('sm')]: {
            padding: '10px 7px',
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          [createTheme().breakpoints.down('sm')]: {
            fontSize: '0.875rem',
            top: -2,
          },
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     // root: {
    //     //   [createTheme().breakpoints.down('sm')]: {
    //     //     padding: '6px 12px',
    //     //   },
    //     // },
    //     root: {
    //       [createTheme().breakpoints.down('sm')]: {
    //         lineHeight: 1,
    //       },
    //     },
    //   },
    // },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       [createTheme().breakpoints.down('sm')]: {
    //         padding: '8px',
    //       },
    //     },
    //   },
    // },
    // MuiCardContent: {
    //   styleOverrides: {
    //     root: {
    //       [createTheme().breakpoints.down('sm')]: {
    //         padding: '8px',
    //         '&:last-child': {
    //           paddingBottom: '8px',
    //         },
    //       },
    //     },
    //   },
    // },
  },
  spacing: (factor) => {
    const baseSpacing = 8;
    const mobileSpacing = 6;
    return `${factor * (window.innerWidth < 600 ? mobileSpacing : baseSpacing)}px`;
  },
});

export default baseTheme;