import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';

import './styles.css';
import baseTheme from './utils/theme';
import config from './constants/config';

import App from './App';

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

if (config.env === 'production') {
  gtag('js', new Date());
  gtag('config', 'G-BSF411E1MC');
}

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <ThemeProvider theme={baseTheme}>
      <App />
    </ThemeProvider>
  </StrictMode>
);
